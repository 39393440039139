export const ShowConfig = {
  isClosed: true,
  isOpenCallOpen: true,
  underConstruction: true,
  isMenuOn: false,
  galleryTitle: "public access memories",
  showTitle: "digital residency",
  showDescription: "TBD",
  showOpens: { date: "March 2025", time: "6PM CST" },
  showCloses: "",
  link: "residency",
  awsLink: "residency",
};
